<template>
<!-- style="height: 25px; width: 100%;" -->
  <div ref="editor" class="v-input" style="height: 25px; width: 100%;"></div>
</template>

<script>
export default {
  name: 'MSLNEditor',
  components: {
  },
  props: {
    value: {
      type: [Object, null],
      required: true
    },
    taskName: {
      type: String,
      required: false
    },
    minLine: {
      type: Number,
      required: false,
      default: 1
    },
    maxLine: {
      type: Number,
      required: false,
      default: 1
    }
  },
  data: () => ({
    editor: null
  }),
  methods: {
    jinja_template: function (str, i) {
      let ch = str.charAt(i)
      let ii = -1
      i += 1
      if (str.charAt(i) === '{') {
        i += 1
        ch = str.charAt(i)
        while (ch) {
          if (ch === '{') {
            ii = this.jinja_template(str, i)
            if (ii > -1) {
              i = ii
            }
          } else if (ch === '}') {
            if (str.charAt(i + 1) === '}') {
              return i + 1
            }
          }
          i += 1
          ch = str.charAt(i)
        }
        return -1
      } else if (str.charAt(i) === '%') {
        i += 1
        ch = str.charAt(i)
        while (ch) {
          if (ch === '%') {
            if (str.charAt(i + 1) === '}') {
              return i + 1
            }
          }
          i += 1
          ch = str.charAt(i)
        }
      } else {
        return -1
      }
    },
    cleanTemplate: function (str) {
      let i = 0
      let ii = -1
      let ch = str.charAt(i)
      let clean = ''
      while (ch) {
        if (ch === '{') {
          if (str.charAt(i + 1) === '{') {
            ii = this.jinja_template(str, i)
            if (ii > -1) {
              i = ii
              clean += '0'
            } else {
              clean += ch
            }
          } else if (str.charAt(i + 1) === '%') {
            i = this.jinja_template(str, i)
          }
        } else {
          clean += ch
        }
        i += 1
        ch = str.charAt(i)
      }
      return clean
    }
  },
  computed: {},
  mounted () {
    this.editor = window.ace.edit(this.$refs.editor)
    this.editor.setTheme('ace/theme/github')
    this.editor.session.setMode('ace/mode/jinja_templates')
    this.editor.session.setTabSize(2)
    this.editor.setOptions({
      minLines: this.minLine,
      maxLines: this.maxLine
    })
    if (this.value && this.value.S) {
      this.editor.setValue(`"${this.value.S}"`)
    } else if (this.value.L) {
      this.editor.setValue(this.value.L)
    } else if (this.value.N) {
      this.editor.setValue(this.value.N)
    } else if (this.value.M) {
      this.editor.setValue(JSON.parse(JSON.stringify(this.value.M, null, '\t'), 1))
    } else {
      this.editor.setValue('')
    }
    let vm = this
    this.editor.session.on('tokenizerUpdate', function (o) {
      let val = vm.editor.getValue()
      try {
        let annotations = vm.editor.session.getAnnotations().filter((a) => a.type === 'error')
        if (annotations.length === 0) {
          let js = JSON.parse(vm.cleanTemplate(val))
          if (vm.taskName === 'iterator') {
            vm.$emit('input', { L: val })
          } else {
            if (typeof js === 'object') {
              if (Array.isArray(js)) {
                vm.$emit('input', { L: val })
              } else {
                vm.$emit('input', { M: val })
              }
            } else if (typeof js === 'number') {
              vm.$emit('input', { N: val })
            } else if (typeof js === 'string') {
              vm.$emit('input', { S: val.replaceAll('"', '') })
            }
          }
        } else {
          vm.$emit('error', annotations)
        }
      } catch (e) {
        vm.$emit('input', { M: val })
        // vm.$emit('error', e)
      }
    })
    this.editor.focus()
    this.editor.navigateFileEnd()
  }
}
</script>
